@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-stripes-green {
    background-color: #16a34a1a;
    background-image: linear-gradient(135deg, #16a34a80 10%, transparent 0, transparent 50%, #16a34a80 0, #16a34a80 60%, transparent 0, transparent);
    background-size: 7.07px 7.07px;
}

@layer base {
    @font-face {
        font-family: 'GrandisBold';
        font-style: normal;
        font-weight: 700;
        src: local('Grandis Extended Bold'), url('../fonts/GrandisBold.woff') format('woff');
        font-display: swap;
    }

    @font-face {
        font-family: 'GrandisMedium';
        font-style: normal;
        font-weight: 500;
        src: local('Grandis Extended Medium'), url('../fonts/GrandisMedium.woff') format('woff');
        font-display: swap;
    }

    @font-face {
        font-family: 'Grandislight';
        font-style: normal;
        font-weight: 300;
        src: local('Grandis Extended Light'), url('../fonts/GrandisLight.woff') format('woff');
        font-display: swap;
    }

    @font-face {
        font-family: 'GilroyExtraBold';
        font-style: normal;
        font-weight: 800;
        src: local('Gilroy Extra Bold'), url('../fonts/Gilroy-ExtraBold.woff') format('woff');
        font-display: swap;
    }

    @font-face {
        font-family: 'GilroyLight';
        font-style: normal;
        font-weight: 300;
        src: local('Gilroy Light'), url('../fonts/Gilroy-Light.woff') format('woff');
        font-display: swap;
    }
}